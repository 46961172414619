:root {
  --background-color: #23242a;
  --background-color-2: #32333c;
  /* --main-color: #e0616c #d54652; */
  --main-color: #f06b46;
  --secondary-color: #ffb627;
  --text-primary: #f7f7f7;
  --text-secondary: #c2c2c5;
}

[data-theme='light'] {
  --background-color: #f7f7f7;
  --background-color-2: #fcfcfc;
  --main-color: #f06b46;
  --secondary-color: #ffb627;
  --text-primary: #32333c;
  --text-secondary: #474750;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Red Hat Text', 'DM Sans', 'Albert Sans', 'Raleway',
    'Zen Kaku Gothic New', sans-serif;
}

.app-wrapper {
  background-color: var(--background-color);
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.intro,
.about,
.skills,
.portfolio,
.contact {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 48px;
}

button {
  border: none;
  font-family: inherit;
  cursor: pointer;
}

input,
textarea {
  outline: none;
  border: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  padding: 35px 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: var(--text-primary);
  z-index: 999;
  background-color: var(--background-color);
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.1);
}

.nav-bar p {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -1.5px;
}

li {
  list-style-type: none;
}

.nav-bar ul {
  font-weight: 500;
}

.nav-item {
  border-bottom: 3px solid transparent;
  transition: border-bottom 200ms ease-out;
}

.nav-item:hover {
  cursor: pointer;
  border-bottom: 3px solid var(--main-color);
}

.nav-menu,
.mobile-nav-menu {
  display: flex;
  font-size: 20px;
  gap: 50px;
}

.nav-bar li:last-child {
  display: flex;
  align-items: center;
}

.nav-icon {
  cursor: pointer;
  height: 20px;
}

.nav-icon path {
  fill: var(--text-primary);
}

.nav-icon:hover {
  transform: scale(1.1);
}

.icon {
  width: 30px;
  display: inline-block;
}

.icon path {
  fill: var(--text-primary);
}

.icon:hover path {
  fill: var(--main-color);
}

.intro-container {
  margin-top: 100px;
}

.intro {
  color: var(--text-primary);
  display: flex;
  align-items: center;
  position: relative;
  min-height: 600px;
  gap: 24px;
}

/* .intro-left {
  display: flex;
  flex-direction: column;
  gap: 12px;
} */

.intro-middle {
  max-width: 45%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.intro-middle button {
  padding: 8px 16px;
  background: linear-gradient(45deg, var(--main-color), var(--secondary-color));
  font-size: 18px;
  color: var(--background-color);
  border-radius: 50px;
  font-weight: 500;
}

.intro-middle button:hover {
  background: linear-gradient(45deg, var(--secondary-color), var(--main-color));
}

.intro-right {
  margin-left: auto;
  margin-top: auto;
  width: 350px;
  height: 350px;
  background-image: linear-gradient(
    to bottom right,
    var(--secondary-color),
    var(--main-color)
  );
  border-radius: 10px;
  position: relative;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.intro-right img {
  height: 100%;
  border-radius: 10px;
  position: absolute;
  top: -20%;
  left: -20%;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.intro-title {
  font-size: 42px;
  font-weight: 700;
  letter-spacing: -1.5px;
  line-height: 1;
  display: block;
}

.intro-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
}

.intro-title span,
.intro-text span,
.about-text span {
  color: var(--main-color);
  font-weight: 700;
}

.about-container {
  background-color: var(--background-color-2);
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.about .section-title {
  width: 100%;
}

.about-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.about-text {
  width: 70%;
  color: var(--text-primary);
  font-size: 18px;
  line-height: 1.5;
}

.section-title {
  margin-bottom: 80px;
  text-align: center;
  color: var(--text-primary);
}

.section-title-main {
  font-size: 36px;
  font-weight: 700;
}

.section-title-sub {
  color: var(--text-secondary);
  font-size: 18px;
  width: 75%;
  margin: 0 auto;
}

.skills {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.skill-cards-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.skills-toggle-container {
  height: 48px;
  width: 300px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  font-size: 20px;
  background-color: var(--background-color-2);
  position: relative;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

.toggle-btn {
  font-size: 16px;
  font-weight: 500;
  background: none;
  width: 50%;
  border-radius: 50px;
  height: 100%;
  color: var(--text-primary);
  display: inline-block;
  z-index: 5;
}

.toggle-btn--active {
  color: var(--background-color);
}

.toggle-box {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  border-radius: 50px;
  background-image: linear-gradient(
    45deg,
    var(--main-color),
    var(--secondary-color)
  );
}

.skill-cards {
  display: none;
  padding: 20px;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  width: 75%;
}

.skill-cards--active {
  display: flex;
}

.skill-item {
  height: 120px;
  width: 120px;
  background-color: var(--background-color-2);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.skill-item p {
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  color: var(--text-primary);
  letter-spacing: 0;
}

.skill-item img {
  height: 45px;
  max-width: 100px;
}

.skill-icon {
  height: 45px;
  width: 100%;
  max-width: 100px;
}

.skill-icon path {
  fill: var(--text-primary);
}

.skill-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.portfolio-container {
  background-color: var(--background-color-2);
}

.portfolio-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0;
  column-gap: 48px;
}

.portfolio-card {
  padding: 48px;
  display: flex;
  align-items: flex-end;
  color: var(--text-primary);
  height: 600px;
  background-size: cover;
  background-position: top left;
  border-radius: 10px;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  transition: transform 200ms ease-in-out;
}

.portfolio-card:nth-child(even) {
  margin-top: 48px;
}

.portfolio-card:hover {
  transform: scale(1.02);
}

.portfolio-card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.portfolio-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--text-primary);
  width: 90%;
}

.project-title {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--main-color);
  font-weight: 700;
}

.project-intro {
  color: #f7f7f7;
  font-size: 18px;
}

.project-tags {
  color: #c2c2c5;
  font-size: 14px;
}

.portfolio-bottom {
  display: flex;
  gap: 10px;
}

.portfolio-link {
  display: block;
  background-image: linear-gradient(
    45deg,
    var(--main-color),
    var(--secondary-color)
  );
  color: var(--background-color);
  padding: 6px 12px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
}

.portfolio-link:hover {
  background-image: linear-gradient(
    45deg,
    var(--secondary-color),
    var(--main-color)
  );
}

.button-icon {
  height: 24px;
}

.button-icon path {
  fill: var(--background-color);
}

.contact-content {
  display: flex;
  min-height: 400px;
  justify-content: center;
  align-items: center;
}

.contact-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.contact-title {
  font-size: 18px;
  color: var(--text-secondary);
  width: 80%;
}

.contact-form {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--text-primary);
}

.form-row {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contact-form input {
  border: none;
  font-size: 16px;
  height: 30px;
  padding: 10px;
  border-radius: 10px;
  font-family: inherit;
}

.contact-form textarea {
  resize: none;
  border-radius: 10px;
  height: 120px;
  padding: 10px;
  font-family: inherit;
  font-size: 16px;
}

.contact-form input,
.contact-form textarea {
  background-color: #ebebec;
  color: #23242a;
}

.form-message {
  opacity: 0;
}

.form-message {
  font-size: 16px;
  color: var(--main-color);
  font-weight: 500;
}

.submit-message {
  font-size: 18px;
  color: var(--text-secondary);
  text-align: center;
}

.contact button {
  padding: 6px 12px;
  background-image: linear-gradient(
    45deg,
    var(--main-color),
    var(--secondary-color)
  );
  border-radius: 50px;
  color: var(--background-color);
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 120px;
}

.contact button:hover {
  background-image: linear-gradient(
    45deg,
    var(--secondary-color),
    var(--main-color)
  );
}

.contact-form button:disabled {
  cursor: not-allowed;
}

.footer {
  height: 120px;
  font-size: 14px;
  color: var(--text-secondary);
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
input:-webkit-autofill:active,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ebebec inset;
  box-shadow: 0 0 0px 1000px #ebebec inset;
}

.menu-icon {
  z-index: 10;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.mobile-nav-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  font-size: 24px;
}

.footer a {
  color: var(--main-color);
}

.contact-icon {
  height: 24px;
}

.footer-logo {
  margin-top: 16px;
}

@media screen and (max-width: 1024px) {
  .nav-menu {
    display: none;
  }

  .intro-title {
    font-size: 30px;
  }

  .intro-text {
    font-size: 16px;
  }

  .intro-right {
    height: 300px;
    width: 300px;
    margin-bottom: auto;
  }

  .intro-right img {
    top: -10%;
    left: -10%;
  }

  .portfolio-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
  }

  .portfolio-card {
    width: 80%;
  }

  .portfolio-card:nth-child(even) {
    margin: 0;
  }

  .contact-content {
    flex-direction: column;
  }

  .footer {
    justify-content: center;
    flex-direction: column;
    gap: 5px;
  }
}

/* desktop */
@media screen and (min-width: 1024px) {
  .menu-icon {
    display: none;
  }
}

/* tablet */
@media screen and (max-width: 768px) {
  .intro {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  /* .intro-left {
    flex-direction: row;
  } */

  .intro-middle {
    max-width: 80%;
  }

  .intro-right {
    margin: 48px 0 0 0;
    transform: translateX(5%);
  }

  .nav-bar p {
    font-size: 24px;
  }

  .section-title {
    font-size: 24px;
  }

  .portfolio-card {
    width: 100%;
  }

  .portfolio-header {
    width: 100%;
  }
}

/* mobile */
@media screen and (max-width: 480px) {
  .app-wrapper {
    overflow: auto;
    min-width: 300px;
  }

  .nav-bar p {
    font-size: 20px;
  }

  .intro-title {
    font-size: 24px;
  }

  .portfolio-bottom {
    flex-direction: column;
    gap: 10px;
  }

  .portfolio-card {
    width: 100%;
    padding: 24px;
  }

  .project-intro,
  .project-title {
    font-size: 14px;
  }

  .about-text {
    width: 100%;
  }

  .intro-right {
    height: 240px;
    width: 240px;
  }
}

.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4.8rem;
  background-color: #1d1e2c;
  color: #f7f7f7;
  font-weight: 700;
}
